.wdk-RecordContainer__GroupRecordClasses\.GroupRecordClass {
  .wdk-MissingMsaAttribute {
    color: darkred;
  }

  th.HeadingCell--key-domains {
    width: 35em;
  }

  td > .Cell-domains {
    vertical-align: middle;
    padding-top: 1.2em;
    padding-bottom: 1.2em;

    .PfamDomain {
      min-width: 0.41666666666em;
    }
  }
}
