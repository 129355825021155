$StickyColumnBorderColor: rgb(204, 204, 204);

.MesaComponent {
  th {
    background-color: #e2e2e2;
    transition: background 0.25s;
    position: relative;
    color: #444;
    text-align: left;

    &[draggable='true'] {
      cursor: -moz-grab;
      cursor: -webkit-grab;
      cursor: grab;
      &:active {
        // cursor: -moz-grabbing;
        // cursor: -webkit-grabbing;
        // cursor: grabbing;
      }
    }

    &:active {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
    }
    &:hover {
      background-color: #d2d2d2;
    }
  }

  td {
    vertical-align: top;
    /*overflow: auto;*/
  }

  th,
  td {
    margin: 0;
    padding: 10px;
    margin-top: -1px;
    border-right: none;
    border-top: 1px solid transparent;
    border-right: 1px solid $borderColor;
    border-bottom: none;

    p {
      margin-top: 0;
    }

    &:first-of-type {
      border-left: 1px solid $borderColor;
    }
  }

  th.SelectionCell,
  td.SelectionCell {
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 4em;
  }

  th.SelectionCell__withDetails {
    display: flex;
    width: auto;
    align-items: center;
    margin-top: 0px;
    span {
      margin-left: 0.5em;
    }
  }

  .StickyColumnCell {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      left: 0;
      box-shadow: -1px 0px 0px $StickyColumnBorderColor,
        1px 0px 0px $StickyColumnBorderColor;
    }

    a {
      position: relative;
      z-index: 1;
    }
  }

  tr {
    &:nth-child(odd) td.StickyColumnCell {
      background-color: $oddRowBg;
    }
    &:nth-child(even) td.StickyColumnCell {
      background-color: $evenRowBg;
    }
  }

  .HeadingCell--Dragging {
    background-color: rgba(0, 0, 0, 0.2);
    color: #444;
    opacity: 0.75;
  }
  .HeadingCell--DragTarget {
    border-right-style: dashed;
    border-right-color: rgba(0, 0, 0, 0.3);
    padding-right: 12px;
    border-right-width: 3px;
    * {
      pointer-events: none;
    }
  }
  .HeadingCell--Dragging.HeadingCell-DragTarget {
    border-right-style: solid;
    border-right-width: 1px;
    padding-right: 15px;
  }
}
